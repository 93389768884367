import {
    Outlet,
    Link,
    NavLink,
    useLoaderData,
    Form,
    redirect,
    useNavigation,
    useSubmit,
    useNavigate
  } from "react-router-dom";
import { useEffect, useState } from "react";
import { getContacts, createContact } from "../contacts";
import { HomePage } from "../components/home/HomePage";
import { UserOutlined } from '@ant-design/icons';
import { Button, Avatar } from "antd";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useAuth } from "../hooks/useAuth";
import { useJwt } from "react-jwt";

const backendUrl = process.env.REACT_APP_BACKEND;
const providersNames = [
  'strava',
  // 'facebook',
  // 'google',
];
const LoginButton = (props) => <a className="nav-item btn btn-primary btn-signup" href={`${backendUrl}/api/connect/${props.providerName}`}>
  Đăng nhập với {props.providerName}
  </a>;
const LogoutButton = (props) => <Link className="nav-item" onClick={props.onClick} >Đăng xuất</Link>;

export async function action() {
    const contact = await createContact();
    return redirect(`/contacts/${contact.id}/edit`);
}

export async function loader({ request }) {
  return { };
}
export default function Root() {
  const { user, isLoggedIn, logout } = useAuth();
  useEffect(() => {
    
  }, []);
  const userData = user?.user;
  let buttons;
  if (isLoggedIn) {
    buttons = <LogoutButton onClick={logout} />;
  } else {
    buttons = <ul style={{ listStyleType: 'none' }}>
      {providersNames.map((providerName, i) => <li key={providerName}>
        <LoginButton providerName={providerName}/>
        </li>)}
    </ul>;
  }
  return (
    <>
    <header id="global-header">
        <nav className="nav-bar container" role="navigation">
            <div title="Return to home page" className="branding">
              <Link className="branding-content" to="/">
                {/* <span className="sr-only">Strava</span> */}
                        <img
                    loading="lazy"
                    src="/logo.png"
                    className="img-2"
                  />
                  {/* <div className="slogan">
                    <span>RUNNING </span><span>CLUB</span>
                  </div> */}
                        </Link>
                        
            </div>
            <div className="nav-container" id="container-nav">
            {/* <ul className="global-nav nav-group"> */}
              {/* <li className="nav-item drop-down-menu accessible-nav-dropdown selected enabled" data-log-category="dashboard" data-log-page="dashboard">
              <a className="selection nav-link accessible-nav-link" href="/dashboard">Dashboard
              </a><button aria-haspopup="" className="selection nav-link accessible-nav-arrow" data-toggle="dropdown " id="dashboard-dropdown-arrow" title="Expand dashboard menu">
              <span className="app-icon-wrapper  "><span className="app-icon icon-caret-down icon-dark"></span></span>
              </button>
              <ul aria-labelledby="dashboard-dropdown-arrow" className="options" role="menu">
              <li className="selected">
              <a href="/dashboard">Activity Feed</a>
              </li>
              <li className="">
              <a href="/clubs/search">Clubs</a>
              </li>
              <li className="">
              <a href="/athlete/segments/starred">My Segments</a>
              </li>
              <li className="">
              <a href="/athlete/routes">My Routes</a>
              </li>
              <li className="premium opt-group">
              <div className="text-caption4 subscription-callout">
              SUBSCRIPTION
              </div>
              <ul>
              <li className="" data-log-element="my_goals">
              <a href="/athlete/goals">My Goals
              </a></li>
              </ul>
              </li>
              </ul>
              </li>
              <li className="nav-item drop-down-menu accessible-nav-dropdown enabled" data-log-category="training" data-log-page="training">
              <a className="selection nav-link accessible-nav-link" href="/athlete/training/log">Training
              </a><button aria-haspopup="" className="selection nav-link accessible-nav-arrow" data-toggle="dropdown " id="dashboard-dropdown-arrow" title="Expand training menu">
              <span className="app-icon-wrapper  "><span className="app-icon icon-caret-down icon-dark"></span></span>
              </button>
              <ul aria-labelledby="dashboard-dropdown-arrow" className="options" role="menu">
              <li className="">
              <a href="/athlete/calendar">Training Calendar</a>
              </li>
              <li className="">
              <a href="/athlete/training">My Activities</a>
              </li>
              <li className="premium opt-group">
              <div className="text-caption4 subscription-callout">
              SUBSCRIPTION
              </div>
              <ul>
              <li>
              <a href="/athlete/training/log">Training Log</a>
              </li>
              <li className="" data-log-element="training_plans">
              <a href="/training-plans">Training Plans
              </a></li>
              <li className="" data-log-element="power_curve">
              <a href="/athlete/analysis">Power Curve
              </a></li>
              <li className="" data-log-element="fitness_and_freshness">
              <a href="/athlete/fitness">Fitness &amp; Freshness
              </a></li>
              </ul>
              </li>
              </ul>
              </li> */}
              {/* <li className="nav-item">
              <Link to={`/campaign`} className="nav-link">Giải chạy</Link>
              </li> */}
              {/* </ul> */}
              {
                isLoggedIn ? 
              <ul className="user-nav nav-group">

                  
              <li className="nav-item drop-down-menu user-menu accessible-nav-dropdown enabled">
              <a className="nav-link selection accessible-nav-link" href="">
              <div className="avatar avatar-athlete">
              {
                userData?.avatar ?
              <img alt={userData?.name} className="avatar-img" src={userData?.avatar} />:
              <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
              }
              </div>
              <span className="athlete-name">
              {userData?.name}
              </span>
              </a>
              <button aria-haspopup="" className="selection nav-link accessible-nav-arrow" data-toggle="dropdown " id="dashboard-dropdown-arrow" title="Expand profile menu">
              <span className="app-icon-wrapper  "><span className="app-icon icon-caret-down icon-dark"></span></span>
              </button>
              <ul className="options">
              <li>
              <Link to={`/activity`} className="nav-link">Các hoạt động</Link>
              </li>
              {/* <li>
                <Link to={`/profile`} className="nav-link">Thông tin tài khoản</Link>
                </li> */}
                <li>
                <LogoutButton onClick={logout} />
                </li>
              </ul>
              </li>
              {/* <li className="nav-item drop-down-menu upload-menu enabled">
              <a className="nav-link selection" href="/upload">
              <div className="upload-button-wrapper">
              <div className="upload-button icon-upload app-icon icon-sm">
              Upload
              </div>
              </div>
              </a>
              <ul className="options">
              <li>
              <a href="/upload">
              <span className="upload-activity app-icon icon-upload-activity"></span>
              Upload activity
              </a>
              </li>
              <li>
              <a href="/upload/manual">
              <span className="upload-activity-manual app-icon icon-upload-activity-manual"></span>
              Add manual entry
              </a>
              </li>
              <li>
              <a href="/routes/new">
              <span className="upload-route app-icon icon-upload-route"></span>
              Create a route
              </a>
              </li>
              <li>
              <a href="/athletes/39565020/posts/new">
              <span className="create-post app-icon icon-create-post"></span>
              Create a post
              </a>
              </li>
              </ul>
              </li> */}
              </ul>
              :
              <ul className="user-nav nav-group">
                <li className="nav-item">{buttons}</li>
              </ul>
              }
            </div>
        </nav>
    </header>
        <div className="page container">
          {/* <HomePage /> */}
          <Outlet />
        </div>
    </>
  );
}